<template>
  <div class="chooseShop">
    <div>
      <h3>กรุณาเลือกสาขาเข้าใช้งาน</h3>
      <div class="option">
        <div
          v-for="(s, index) in shops"
          :key="index"
          data-tag="selectShop"
          @click="setShop(s)"
        >
          {{ s.code }} {{ s.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jwtDecode from "jwt-decode";
// import errorCode from "../utils/errorCode";

export default {
  // props: { targetPath: { default: "/", type: String } },
  data() {
    return {
      shops: [],
      targetPath: "/",
    };
  },
  computed: {},
  methods: {
    setShop(shop) {
      if (!window.$permission) window.$permission = {};
      window.$permission.myShop = shop;
      console.log("set branch", window.$permission.myShop);
      this.$router.replace(this.targetPath);
    },
  },
  beforeMount() {
    console.log("choose branch");
    const jwtData = jwtDecode(window.$apiToken);
    this.shops = jwtData.s;

    // get target path
    this.targetPath = this.$route.query.targetPath.startsWith("/chooseBranch")
      ? "/"
      : this.$route.query.targetPath;

    // get params from the target path
    const targetPathParams = new URLSearchParams(this.targetPath.split("?")[1]);

    // get seller shop by seller id from query parameters
    const sellerShop = this.shops.find(
      (e) => targetPathParams.get("setSellerId") === e.id.toString()
    );

    // check for auto set shop
    if (sellerShop) {
      this.setShop(sellerShop);
    }
  },
};
</script>
<style lang="scss" scoped>
.chooseShop {
  text-align: center;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--primary-color);
  > div {
    width: 100%;
  }
}

h3 {
  margin-bottom: 30px;
}

.option {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  > div {
    border: 1px solid rgb(190, 190, 190);
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
  }
}
</style>
